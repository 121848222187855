import { useInjection } from 'inversify-react';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient';
import IUIService from '../../services/IUIService';
import { IUserValidator } from '../../services/IUserValidator';
import conditionalClass from '../../utils/conditional-class';
import AuthPageWrapper from '../auth-page-wrapper/auth-page-wrapper.component';
import ErrorContainer from '../error-container/error-container.component';
import './reset-password.styles.scss'

export default function ResetPassword() {
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get('key');

    const authGateRestClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );
    const userValidator = useInjection<IUserValidator>('IUserValidator');
    const uiService = useInjection<IUIService>('IUIService');

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [success, setSuccess] = useState(false);

      const to = searchParams.get('to');

    const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (!key) {
            setPasswordError('Invalid reset URL!');
            return;
        }

        const passwordValidation = userValidator.validatePassword(
            password,
            passwordConfirm
        );

        if (!passwordValidation.isSuccess) {
            setPasswordError(passwordValidation.message);
            return;
        }

        await uiService.showLoading();
        const message = await authGateRestClient.resetPassword(password, key);
        await uiService.hideLoading();

        if (!message) {
            setSuccess(true);
            setPasswordError('');
            return;
        }

        setPasswordError(message.value);
    };

    const header = () => {
        return !key ? (
            <div className="header-row success-row">
                <h3>Wrong Reset Link!</h3>
            </div>
        ) : !success ? (
            <div>
                <div className="header-row login-header">
                    <span>Reset password</span>
                </div>
                <div className="header-row info-message">
                    <span>please enter new password</span>
                </div>
            </div>
        ) : (
            <div className="header-row success-row">
                <h3>Password Reset Success!</h3>
            </div>
        );
    };

    const renderError = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row success-row">
                    <p>
                        Go to <Link to="/login">login!</Link>
                    </p>
                </div>
                <div className="login-dialog-row success-row">
                    <p>
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </p>
                </div>
            </React.Fragment>
        );
    };

    const renderSuccess = () => {
        let loginLink = "/login";
        if(!!to){
            loginLink+=`?to=${to}`;
        }
        return (
            <React.Fragment>
                <div className="login-dialog-row success-row">
                    <p>
                        You can <Link to={loginLink}>login</Link> now using the new
                        password
                    </p>
                </div>
            </React.Fragment>
        );
    };

    const renderRegular = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={password}
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                        }}
                        placeholder="Password"
                    />
                </div>

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={passwordConfirm}
                        onChange={(e) => {
                            e.preventDefault();
                            setPasswordConfirm(e.target.value);
                        }}
                        placeholder="Confirm Password"
                    />
                </div>
                <ErrorContainer error={passwordError} />

                <div className="button-container login-dialog-row input-row">
                    <input
                        type="button"
                        onClick={handleSubmit}
                        id="wod-login-btn"
                        value="Reset Password"
                        disabled={!buttonEnabled}
                    />
                </div>
            </React.Fragment>
        );
    };

    const buttonEnabled = password.length > 0 && passwordConfirm.length > 0;

    return (
        <AuthPageWrapper
            popupId="reset-password"
            popupClass={[
                conditionalClass(success || !key, 'success', undefined),
            ]}
            header={header()}
            privacy
        >
            {!key
                ? renderError()
                : !success
                ? renderRegular()
                : renderSuccess()}
        </AuthPageWrapper>
    );
}
