import 'reflect-metadata'
import React from 'react'
import './App.scss'
import { Container } from 'inversify'
import { Provider } from 'inversify-react'
import { Provider as ReduxProvider } from 'react-redux'
import { AuthRESTClient } from './rest-clients/Implementations/AuthRESTClient'
import PageWithHeader from './pages/page-with-header/PageWithHeader'
import { AuthGateRESTClient } from './rest-clients/Implementations/AuthGateRESTClient'
import store from './redux/store'
import LoadingOverlay from './components/loading-container/loading-container.component'
import ConfirmDialog from './components/confirm-dialog/confirm-dialog.component'
import { AuthService } from './services/Implementations/AuthService'
import { SessionStorageService } from './services/Implementations/SessionStorageService'
import { UserValidator } from './services/Implementations/UserValidator'
import { WindowMessage } from './services/Implementations/WindowMessage'
import UIService from './services/Implementations/UIService'
import { LocalStorageService } from './services/Implementations/LocalStorageService'
import TransRESTClient from './rest-clients/Implementations/TransRESTClient'
import TransService from './services/Implementations/TransService'
import ExtraService from './services/Implementations/ExtraService'
import CookieService from './services/Implementations/CookieService'

class App extends React.Component {
    private readonly container: Container = new Container()

    constructor(props: {}) {
        super(props);
        this.container.bind('IAuthRESTClient').to(AuthRESTClient).inSingletonScope()
        this.container.bind('IAuthGateRESTClient').to(AuthGateRESTClient).inSingletonScope()
        this.container.bind('ITransRESTClient').to(TransRESTClient).inSingletonScope()
        this.container.bind('IAuthService').to(AuthService).inSingletonScope()
        this.container.bind('ITransService').to(TransService).inSingletonScope()
        this.container.bind('ISessionStorageService').to(SessionStorageService).inSingletonScope()
        this.container.bind('ILocalStorageService').to(LocalStorageService).inSingletonScope()
        this.container.bind('IUserValidator').to(UserValidator).inSingletonScope()
        this.container.bind('IWindowMessage').to(WindowMessage).inSingletonScope()
        this.container.bind('IUIService').to(UIService).inSingletonScope()
        this.container.bind('IExtraService').to(ExtraService).inSingletonScope()
        this.container.bind('ICookieService').to(CookieService).inSingletonScope()
    }

    render() {
        return (
            <div id="background-wrap">
                <ReduxProvider store={store}>
                    <Provider container={this.container}>
                        <React.Fragment>
                            <PageWithHeader />
                            <LoadingOverlay/>
                            <ConfirmDialog/>
                        </React.Fragment>
                    </Provider>
                </ReduxProvider>
            </div>
        )
    }
}

export default App
