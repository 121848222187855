import React from 'react';
import LoginPanelHeader from '../login-panel-header/login-panel-header.component';
import './auth-page-wrapper.styles.scss';

interface AuthPageWrapperWrapperProps {
    popupClass?: (string | undefined)[] | undefined;
    children: React.ReactNode | undefined;
    header?: React.ReactNode | undefined;
    popupId?: string;
    privacy?: boolean;
    noIndent? : boolean
    indent? : number
}

export default function AuthPageWrapper(props: AuthPageWrapperWrapperProps) {
    return (
        <div id="page-auth-wrapper">
            <LoginPanelHeader />
            {!props.noIndent && <div style={{height: (!!props.indent?`${props.indent}%`:'20%')}} className="indent">{props.header}</div>}
            <div className="page-main">
                <div className="middle-container">{props.children}</div>
            </div>
            {props.privacy && (
                <div className="privacy-container">
                    <a
                        rel="noreferrer"
                        href="https://kingdomdeath.com/legal/terms"
                        target="_blank"
                    >
                        Terms
                    </a>
                    <a
                        rel="noreferrer"
                        href="https://kingdomdeath.com/legal/privacy"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </div>
            )}
        </div>
    );
}
