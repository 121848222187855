import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient';
import IUIService from '../../services/IUIService';
import { IUserValidator } from '../../services/IUserValidator';
import conditionalClass from '../../utils/conditional-class';
import AuthPageWrapper from '../auth-page-wrapper/auth-page-wrapper.component';
import ErrorContainer from '../error-container/error-container.component';
import './forgot-password.styles.scss';

export default function ForgotPassword() {
    const searchParams = new URLSearchParams(window.location.search);
    const userValidator = useInjection<IUserValidator>('IUserValidator');
    const uiService = useInjection<IUIService>('IUIService');
    const authGateRESTClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [emailError, setEmailError] = useState('');
    const to = searchParams.get('to');
    const reset = async (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();

        const emailValidation = userValidator.validateEmail(email);

        if (!emailValidation.isSuccess) {
            setEmailError(emailValidation.message);
            return;
        }
        uiService.showLoading();
        const message = await authGateRESTClient.reset(email, !!to?to:null);
        uiService.hideLoading();

        if (message === null) {
            setSuccess(true);
            return;
        } else {
            setEmailError(message.value);
        }
    };

    const header = () => {
        return success?headerSuccess():headerDefault();
    };

    const headerDefault = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="login-header">
                        <span>Reset password</span>
                    </div>
                    <div className="info-message">
                        <span>please enter your email</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const headerSuccess = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="login-header">
                        <span>Your Reset Request has been Received!</span>
                    </div>
                    <div className="info-message">
                        <span>Please check your email and follow the link to reset
                            the password</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const buttonEnabled =
        email.length > 0 && userValidator.validateEmail(email);

    return (
        <AuthPageWrapper
            popupId="reset-password"
            popupClass={[conditionalClass(success, 'success', undefined)]}
            privacy={true}
            header={header()}
        >
            {!success ? (
                <React.Fragment>
                    <div className="login-dialog-row input-row">
                        <input
                            type="email"
                            id="wod-email"
                            className={!!emailError ? 'has-error' : undefined}
                            value={email}
                            onChange={(e) => {
                                e.preventDefault();
                                setEmail(e.target.value);
                            }}
                            placeholder="Email"
                        />
                    </div>
                    <ErrorContainer error={emailError} />

                    <div className="button-container login-dialog-row input-row">
                        <input
                            type="button"
                            onClick={reset}
                            id="wod-login-btn"
                            value="Reset Password"
                            disabled={!buttonEnabled}
                        />
                    </div>

                    <div className="login-dialog-row forgot-password-section">
                        <p>
                            <Link to="/login">Back to Login!</Link>
                        </p>
                    </div>
                </React.Fragment>
            ) : (<div></div>
            )}
        </AuthPageWrapper>
    );
}
