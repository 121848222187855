import { inject, injectable } from "inversify";
import type ITransRESTClient from "../../rest-clients/ITransRESTClient";
import ITransService from "../ITransService";

@injectable()
export default class TranService implements ITransService{
    @inject('ITransRESTClient')
    private readonly transRESTClient! : ITransRESTClient;
    
    async processProductKey(key:string):Promise<boolean>{
        return await this.transRESTClient.processProductKey(key);
    }
}