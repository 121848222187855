import axios, { AxiosError } from 'axios';
import ActivateResponse from '../../models/ActivateResponse';
import CaptchaValidationModel from '../../models/auth/CaptchaValidationModel';
import RegistrationModel from '../../models/auth/RegistrationModel';
import { ErrorMessage } from '../../models/ErrorMessage';
import ExtraPayload from '../../models/ExtraPayload';
import JWTAccessToken from '../../models/JWTAccessToken';
import RegisterErrorMessage from '../../models/RegisterErrorMessage';
import { IAuthGateRESTClient } from '../IAuthGateRESTClient';
import { RESTClient } from './RESTClient';

export class AuthGateRESTClient
    extends RESTClient
    implements IAuthGateRESTClient
{
    constructor() {
        super();
        this.setBaseURL(
            'https://wod-auth-gate-uux56memxa-uc.a.run.app/api/v1/'
        );
    }

     async verifyCaptcha(captcha: string): Promise<boolean> {
        const form = new FormData();
        form.append('response', captcha);

        const path = this.getFullURL('captcha');

        try {
            await axios.post(path, form);
            return true;
        } catch (error) {
            return false;
        }
    }

    

    async register(email: string, extra:string|null,  password: string, captcha: string, siteKey : string): Promise<RegisterErrorMessage | null> {
        const captchaBody : CaptchaValidationModel = {
            response : captcha,
            siteKey : siteKey
        };

        const registrationBody : RegistrationModel = {
            email:email,
            password:password,
            captcha:captchaBody, 
            extra : extra
        };


        const path = this.getFullURL('user');

        try {
            const jsonString = JSON.stringify(registrationBody);
            const response = await axios.post(path, jsonString, this.getHeaderJson());
            if (this.isSuccess(response)) return null;
        } catch (error) {
            if(axios.isAxiosError(error)){
                const axiosError = error as AxiosError;
                if (axiosError.response!.status === 401)
                    return { isSuccess:false, generalError: 'Captcha validation failed!' };
                if(axiosError.response!.status===409)
                    return { isSuccess:false, emailError: 'Email is already taken!' };
                if(axiosError.response!.status===400)
                    return { isSuccess:false, generalError: 'Invalid data provided!' };
                return { isSuccess:false, generalError: 'Unexpected error!' };
            }
          
        }

        return { isSuccess:false, generalError:'Unexpected error' };
    }

    
    async resendEmail(email: string, extra:string|null): Promise<boolean> {
        const path = this.getFullURL(`user/${email}/activation`);

        const data = {
            extra
        };

        try {
            const response = await axios.put(path, JSON.stringify(data), this.getHeaderJson());
            return true;
        } catch (error) {
            return false
        }
    }

    async reset(email: string, to: string|null): Promise<ErrorMessage> {
        const path = this.getFullURL(`user/${email}/password`);
        const formData = new FormData();
        if(!!to)
            formData.set('to', to);
        else
            formData.set('to', '-1');

        try {
            const response = await axios.put(path, formData);
            if (this.isSuccess(response)) return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response!.status===404)
                    return { value: 'User not found!' };
            }
        }

        return { value: 'Unexpected error!' };
    }

    

    async activateUser(key: string): Promise<ActivateResponse> {
        const url = this.getFullURL('user/activation');
        try {
            const formData = new FormData();
            formData.set('hash', key);
            const response = await axios.put(url, formData);
            const jwt = response.data as JWTAccessToken;
            return {
                isSuccess : true,
                jwt : jwt,
                message : 'Account authenticated successfully!'
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 404) {
                    return {
                        isSuccess : false,
                        message : 'User not found. Please re-check your email'
                    };
                }
                if (axiosError.response?.status === 400) {
                    return {
                        isSuccess : false,
                        message : 'Invalid authentication link! Please check your email for the correct link!'
                    };
                }
                if (axiosError.response?.status === 500) {
                    return {
                        isSuccess : false,
                        message :'A problem was encountered with the authentication process.'
                    };
                }
            }
            return {
                isSuccess : false,
                message :'A problem was encountered with the authentication process.'
            };
        }
    }

    async resetPassword(password: string, hash: string): Promise<ErrorMessage> {
        const form = new FormData();
        form.append('password', password);
        form.append('token', hash);

        const path = this.getFullURL('password');

        try {
            await axios.put(path, form);
            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '404')
                    return { value: 'Could not find user!' };

                if (axiosError.code === '406')
                    return {
                        value: 'Reset Token is not acceptable. Please request a new one.',
                    };

                if (axiosError.code === '400')
                    return {
                        value: 'Supplied password is invalid. Please choose a different one.',
                    };

                if (axiosError.code === '500')
                    return {
                        value: 'A problem was encountered while submitting the request.',
                    };
            }
        }

        return { value: 'Unexpected error!' };
    }

    async resetEmail(token:string) : Promise<ErrorMessage>{
        const form = new FormData();
        form.append('token', token);

        const path = this.getFullURL('email');

        try {
            await axios.put(path, form);
            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const code = error.response?.status;
                if (code === 404)
                    return { value: 'Could not find user!' };

                if (code === 406)
                    return {
                        value: 'Reset Token is not acceptable. Please request a new one.',
                    };

                if (code === 400)
                    return {
                        value: 'Supplied email is invalid. Please choose a different one.',
                    };

                if (code === 409)
                    return {
                        value: 'Email is already in use.',
                    };

                if (code === 500)
                    return {
                        value: 'A problem was encountered while submitting the request.',
                    };
            }
        }

        return { value: 'Unexpected error!' };
    }

    async parseExtra(extra:string):Promise<ExtraPayload | null>{
        const url = this.getFullURL('extra/'+extra);
        try {
            const response = await axios.get(url);
            const extra = response.data as ExtraPayload;
            return extra;
        } catch (error) {
            return null;
        }
    }
}
