import React from 'react';

interface ConfirmAccountComponentProps {
    message: string;
    timeout: boolean;
    resend: () => void;
}

interface ConfirmAccountComponentState {
    timeLeft: number;
}

export default class ConfirmAccountComponent extends React.Component<
    ConfirmAccountComponentProps,
    ConfirmAccountComponentState
> {

    private interval! : NodeJS.Timeout;

    constructor(props: ConfirmAccountComponentProps) {
        super(props);
        this.state = {
            timeLeft: 0,
        };
    }

    resendClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.setState(
            {
                timeLeft: 60,
            },
            () => {
                if(this.interval)
                    clearInterval(this.interval);
                this.interval = setInterval(() => {
                    const { timeLeft } = this.state;

                    if (timeLeft === 0) {
                        clearInterval(this.interval);
                        return;
                    }
                    this.setState({
                        ...this.state,
                        timeLeft: timeLeft - 1,
                    });
                }, 1000);
            }
        );

        this.props.resend();
    };
    render() {
        const { message } = this.props;
        const { timeLeft } = this.state;
        return (
            <div className="info-message">
                <span>{message}</span>{' '}
                <br/>
                <span>Did not receive confirmation email?</span>{' '}
                {timeLeft === 0 ? (
                    <button className="link" onClick={this.resendClick}>
                        Click here to resend!
                    </button>
                ) : (
                    <button className="link" disabled>
                        Wait {timeLeft}s to resend confirmation email!
                    </button>
                )}
            </div>
        );
    }
}
