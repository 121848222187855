import { useInjection } from 'inversify-react';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient';
import IUIService from '../../services/IUIService';
import { IUserValidator } from '../../services/IUserValidator';
import ErrorContainer from '../error-container/error-container.component';
import './registration-panel.styles.scss';
import KDMRecaptchaWrapper from '../kdm-recaptcha-wrapper/kdm-recaptcha-wrapper.component';
import { IWindowMessage } from '../../services/IWindowMessage';
import { IAuthService } from '../../services/IAuthService';
import ConfirmAccountComponent from '../confirm-account-component/confirm-account.component';
import LoginPanelHeader from '../login-panel-header/login-panel-header.component';
import data from '../../data/registerText.json';
import { ILocalStorageService } from '../../services/ILocalStorageService';
import houseKeyLogo from '../../assets/images/HouseKeySVG.svg'

export default function RegistrationPanel() {
    const authGateRestClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );
    const localStorageService = useInjection<ILocalStorageService>(
        'ILocalStorageService'
    );
    const authService = useInjection<IAuthService>('IAuthService');
    const windowMessage = useInjection<IWindowMessage>('IWindowMessage');
    const userValidator = useInjection<IUserValidator>('IUserValidator');
    const uiService = useInjection<IUIService>('IUIService');
    let recaptcha = useRef<KDMRecaptchaWrapper | null>();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successHeader, setSuccessHeader] = useState('Registration Success!');
    const searchParams = new URLSearchParams(window.location.search);

    const invite = searchParams.get('invite');
    console.log(invite);
    const registerClick = async () => {
        if (!formIsValid()) return;
        let captcha: string | null = '';

        if (!recaptcha.current) return;

        try {
            captcha = await recaptcha.current.executeAsync();
        } catch {
            setGeneralError('Invalid captcha');
            return;
        }

        if (!captcha) {
            setGeneralError('Invalid captcha');
            return;
        }
        uiService.showLoading();

        if (!!invite) {
            localStorageService.set('qrGift', invite);
        }

        const result = await authGateRestClient.register(
            email,
            !!invite ? `qrC${invite}` : searchParams.get('extra'),
            password,
            captcha,
            '6LcoHMsfAAAAAARqmy9Iyxpbt1K_Wq_Pjbgn9S5r'
        );
        uiService.hideLoading();
        if (!result || (result && result.isSuccess)) {
            const searchParams = new URLSearchParams(window.location.search);
            const from = searchParams.get('from');

            const isStore = from && from === 'store';
            const isActivateKey = from && from === 'gift';
            if (isStore || isActivateKey) {
                const timer = setInterval(async () => {
                    const login = await authService.loginJWT(email, password);
                    if (!login) return;
                    if (isStore || isActivateKey) {
                        windowMessage.postMessageToSpawner({
                            key: 'jwt',
                            value: login.jwt,
                        });
                    }
                    clearInterval(timer);
                }, 1000);
                setSuccessMessage(
                    data.successMessages.store
                );
            } else {
                if (!!invite) {
                    setSuccessMessage(
                        data.successMessages.invite
                    );
                } else {
                    setSuccessMessage(
                        data.successMessages.default
                    );
                }
            }
            setSuccess(true);
        } else {
            setSuccess(false);
            if (result.emailError) {
                setEmailError(result.emailError);
                return;
            }
            if (result.passwordError) {
                setPasswordError(result.passwordError);
                return;
            }
            if (result.generalError) {
                setGeneralError(result.generalError);
                return;
            }
            setGeneralError('Invalid information!');
        }
    };

    const formIsValid = () => {
        const emailValidation = userValidator.validateEmail(email);
        const passwordValidation = userValidator.validatePassword(
            password,
            confirmPassword
        );
        setGeneralError('');
        setEmailError('');
        setPasswordError('');
        if (!acceptTerms) {
            setGeneralError('Please accept terms and conditions!');
            return false;
        }
        if (!emailValidation.isSuccess && email && email.length > 0) {
            setEmailError(emailValidation.message);
            return false;
        }
        if (!passwordValidation.isSuccess && password && password.length > 0) {
            setPasswordError(passwordValidation.message);
            return false;
        }
        return (
            emailValidation.isSuccess &&
            password.length > 0 &&
            passwordValidation.isSuccess
        );
    };

    const buttonEnabled =
        userValidator.validateEmail(email).isSuccess &&
        password.length > 0 &&
        confirmPassword.length > 0;

    const secondColumn = () => {
        return success ? secondColumnSuccess() : secondColumnRegistration();
    };
    const secondColumnSuccess = () => {
        return (
            <div className="register-panel success">
                <div className="header-container">
                    <LoginPanelHeader />
                </div>
                <div className="middleContainer">
                    <div className="login-header">
                        <span>{successHeader}</span>
                    </div>
                    <div className="info-message">
                        <ConfirmAccountComponent
                            message={successMessage}
                            timeout
                            resend={() => {
                                authService.resendEmail(
                                    email,
                                    searchParams.get('extra')
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const header = (function () {
        if (!!invite) {
            return data.headers.invite;
        }
        return data.headers.default;
    })();

    const helper = (function () {
        if (!!invite) {
            return data.helper.invite;
        }
        return data.helper.default;
    })();

    const houseKey = (function () {
        if (!!invite) {
            return <img src={houseKeyLogo} className='house-key-style' alt='House Key Logo'></img>;
        }
        return ('');
    })();

    const loginLink = (function(){
        if(!!invite){
            return '/login?invite='+invite;
        }
        return '/login';
    })();

    const secondColumnRegistration = () => {
        return (
            <div className="register-panel">
                <div className="header-container">
                    <LoginPanelHeader />
                    {houseKey}
                    <div className="login-header">
                        <span>{header}</span>
                    </div>
                    <div className="info-message">
                        <span>{helper}</span>
                    </div>
                </div>
                <div className="login-dialog-row input-row">
                    <input
                        type="email"
                        id="wod-email"
                        className={!!emailError ? 'has-error' : undefined}
                        value={email}
                        onChange={(e) => {
                            e.preventDefault();
                            setEmail(e.target.value);
                        }}
                        placeholder="Email"
                    />
                </div>
                <ErrorContainer error={emailError} />

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={password}
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                        }}
                        placeholder="Password"
                    />
                </div>

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={!!passwordError ? 'has-error' : undefined}
                        value={confirmPassword}
                        onChange={(e) => {
                            e.preventDefault();
                            setConfirmPassword(e.target.value);
                        }}
                        placeholder="Confirm Password"
                    />
                </div>
                <ErrorContainer error={passwordError} />
                <div className="login-dialog-row input-row terms-row">
                    <label>
                        <input
                            type="checkbox"
                            id="wod-terms"
                            className={
                                !!passwordError ? 'has-error' : undefined
                            }
                            checked={acceptTerms}
                            onChange={(e) => {
                                setAcceptTerms(e.target.checked);
                            }}
                        />
                        <span>
                            Accept{' '}
                            <a
                                rel="noreferrer"
                                href="https://kingdomdeath.com/legal/terms"
                                target="_blank"
                            >
                                terms and conditions
                            </a>
                        </span>
                    </label>
                </div>
                <ErrorContainer error={generalError} />

                <div className="button-container login-dialog-row input-row">
                    <input
                        type="button"
                        onClick={registerClick}
                        id="wod-login-btn"
                        value="Register"
                        disabled={!buttonEnabled}
                    />
                </div>
                <hr className="divider" />
                <div
                    className="login-dialog-row forgot-password-section"
                    style={{ marginBottom: '20px' }}
                >
                    <p>
                        Already have an account?{' '}
                        <Link to={loginLink}>Login instead!</Link>
                    </p>
                </div>

                <KDMRecaptchaWrapper
                    ref={(e) => (recaptcha.current = e)}
                    sitekey="6LcoHMsfAAAAAARqmy9Iyxpbt1K_Wq_Pjbgn9S5r"
                    //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    // onVerify={(v) => setCaptcha(v)}
                    size="invisible"
                    //enterprise
                />
            </div>
        );
    };

    return (
        <div id="page-auth-wrapper" className="registration-panel-wrapper">
            <div className="indent-registration"></div>
            <div className="page-main registration-main-page">
                <div className="middle-container">
                    <div className="row-registration">
                        <div className="column column-first background"></div>
                        <div className="column column-registration">
                            {secondColumn()}
                        </div>
                    </div>

                    <div className="row-registration">
                        <div className="column column-first"></div>
                        <div className="column column-registration">
                            <div className="privacy-container-panel">
                                <a
                                    rel="noreferrer"
                                    href="https://kingdomdeath.com/legal/terms"
                                    target="_blank"
                                >
                                    Terms
                                </a>
                                <a
                                    rel="noreferrer"
                                    href="https://kingdomdeath.com/legal/privacy"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
