import axios from 'axios';
import { inject, injectable } from 'inversify';
import type { IAuthService } from '../../services/IAuthService';
import ITransRESTClient from '../ITransRESTClient';
import { RESTClient } from './RESTClient';

@injectable()
export default class TransRESTClient
    extends RESTClient
    implements ITransRESTClient
{
    @inject('IAuthService')
    private authService!: IAuthService;

    constructor() {
        super();
        
        this.setBaseURL('https://wod-trans-uux56memxa-uc.a.run.app/api/v1/');
        //this.setBaseURL('https://localhost:44342/api/v1/');
    }


    async processProductKey(key: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const transToken = await this.getToken(token);

        if(!transToken)
        return false;

        const form = new FormData();
        form.set('productKey', key);

        const iss = await this.authService.getISS();

        if(!iss)
        return false;

        const url = this.getFullURL(`customer/${iss}/product-key`);

        try{
            await axios.put(url, form, this.getHeader(transToken));
            return true;
        }
        catch{
            return false;
        }
    }
   

}
