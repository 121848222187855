import { inject, injectable } from "inversify";
import * as ISessionStorageService from "../ISessionStorageService";
import { IWindowMessage } from "../IWindowMessage";

@injectable()
export class WindowMessage implements IWindowMessage{
    @inject('ISessionStorageService')
    private readonly sessionStorageService! : ISessionStorageService.ISessionStorageService;

    constructor() {
        window.addEventListener('message', (event:MessageEvent)=>{
            if(event.data && event.data.type && event.data.key){
                if(event.data.type === 'get'){
                    const value = this.sessionStorageService.get(event.data.key);
                    if(event.source){
                        event.source.postMessage({key:event.data.key, value: value}, {targetOrigin:'*'});
                    }
                }
            }
        }, false);
    }

    postMessageToSpawner(message: any): void {
        if(window.opener)
            window.opener.postMessage(message, {targetOrigin:'*'});
    }

}