import { CSSProperties, HTMLProps, ReactNode, useRef, useEffect } from 'react';
import styles from './overlay-styles.module.scss';
import { Modal } from 'react-overlays';
import { RenderModalBackdropProps } from 'react-overlays/cjs/Modal';

export type OverlayProps = {
    /** Whether the overlay is open */
    open: boolean;
    /** Whether to close the overlay when clicked */
    closeOnClick?: boolean;
    /** Whether to close the overlay when the escape key is pressed */
    closeOnEsc?: boolean;
    /** Action when overlay closes */
    onClose?(): void;
    /** Content of the overlay */
    children?: ReactNode;
    /** Affects backdrop */
    backdrop? : BackDropProps
} & HTMLProps<HTMLDivElement>;

/**
 * Lock body scrolling overflow
 * @param state Whether to lock or not
 */
function lockScroll(state: boolean) {
    if (typeof document === 'undefined') {
        return;
    }

    document.documentElement.style.overflow = state ? 'hidden' : '';
}

/**
 * A lightweight and performant fullscreen overlay component using React portals to render anywhere you need them to
 */
export function Overlay({
    open,
    closeOnClick,
    closeOnEsc = true,
    onClose = () => null,
    children,
    backdrop,
    ...attrs
}: OverlayProps) {

    if (open) {
        lockScroll(true);
    } else {
        lockScroll(false);
    }

    useEffect(() => {
        function onEsc(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                closeOnEsc && onClose();
            }
        }

        document.addEventListener('keydown', onEsc);

        return document.removeEventListener('keydown', onEsc);
    }, [closeOnEsc, onClose]);

    const renderBackdrop = (props: RenderModalBackdropProps) => <Backdrop {...backdrop} {...props} />;

    return (
        <Modal onBackdropClick={x=>{
            x.preventDefault();
            if(closeOnClick){
                onClose();
            }
        }} className={styles.modal} show={open} renderBackdrop={renderBackdrop}>
            <div className={styles.modalInner}>{children}</div>
        </Modal>
    );
}

interface BackDropProps extends RenderModalBackdropProps {
    backgroundColor?: string;
    opacity?: number;
}

function Backdrop({ backgroundColor, opacity }: BackDropProps) {
    return (
        <div
            className={styles.backdrop}
            style={{
                backgroundColor: backgroundColor,
                opacity: opacity,
            }}
        ></div>
    );
}
