import { useInjection } from 'inversify-react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient';
import IUIService from '../../services/IUIService';
import { IUserValidator } from '../../services/IUserValidator';
import conditionalClass from '../../utils/conditional-class';
import AuthPageWrapper from '../auth-page-wrapper/auth-page-wrapper.component';
import ErrorContainer from '../error-container/error-container.component';
import './reset-email.styles.scss'
import { IAuthService } from '../../services/IAuthService';

export default function ResetEmail() {

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get('key');

    const authGateRestClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );

    const authService = useInjection<IAuthService>('IAuthService')

    const uiService = useInjection<IUIService>('IUIService')
    
    const to = searchParams.get('to');

    useEffect(()=>{
        handleSubmit()
    }, [])

    const handleSubmit = async () => {

        if (!key) {
            setError('Invalid reset URL!');
            return;
        }

        uiService.showLoading();
        const message = await authGateRestClient.resetEmail(key);
        uiService.hideLoading();

        if (!message) {
            authService.logout();
            setSuccess(true);
            setError('');
            return;
        }

        setError(message.value);
    };

    const header = () => {
        return !key ? (
            <div className="header-row success-row">
                <h3>Wrong Reset Link!</h3>
            </div>
        ) : !success ? (
            <div>
                <div className="header-row login-header">
                    <span>Change Email</span>
                </div>
                <div className="header-row info-message">
                    <span>please wait for the reset to be complete</span>
                </div>
            </div>
        ) : (
            <div className="header-row success-row">
                <h3>Email Change Success!</h3>
            </div>
        );
    };

    const renderError = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row success-row">
                    <p>
                        Go to <Link to="/login">login!</Link>
                    </p>
                </div>
            </React.Fragment>
        );
    };

    const renderSuccess = () => {
        let loginLink = "/login";
        if(!!to){
            loginLink+=`?to=${to}`;
        }
        return (
            <React.Fragment>
                <div className="login-dialog-row success-row">
                    <p>
                        You can <Link to={loginLink}>login</Link> now using the new
                        email
                    </p>
                </div>
            </React.Fragment>
        );
    };

    const renderRegular = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row input-row">
                   
                </div>

                <div className="login-dialog-row input-row">
                   
                </div>
                <ErrorContainer error={error} />
            </React.Fragment>
        );
    };


    return (
        <AuthPageWrapper
            popupId="reset-password"
            popupClass={[
                conditionalClass(success || !key, 'success', undefined),
            ]}
            header={header()}
            privacy
        >
            {!key
                ? renderError()
                : !success
                ? renderRegular()
                : renderSuccess()}
        </AuthPageWrapper>
    );
}
