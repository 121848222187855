import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../login-page/LoginPage';
import NoAccessPage from '../no-access/NoAccessPage';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import RegistrationPage from '../registration-page/RegistrationPage.component';
import ForgotPasswordPage from '../forgot-password-page/ForgotPasswordPage';
import ResetPasswordPage from '../reset-password-page/ResetPasswordPage';
import ActivationPage from '../activation-page/ActivationPage';
import NoPage from '../no-page/NoPage';
import QRActivationPage from '../../components/qr-activation-page/qr-activation-page.component';
import ResetEmailPage from '../reset-email-page/ResetEmailPage';

class PageWithHeader extends React.Component<{}, {}> {
    
    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegistrationPage />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="/reset-password" element={<ResetPasswordPage />} />
                        <Route path="/reset-email" element={<ResetEmailPage />} />
                        <Route path="/activate" element={<ActivationPage />} />
                        <Route path="/qr-activation" element={<QRActivationPage />} />
                        <Route index element={<Navigate to="/login" replace/>}/>
                    </Routes>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
});

export default connect(mapStateToProps)(PageWithHeader);
