/* eslint-disable react/jsx-no-undef */
import { resolve } from 'inversify-react';
import React from 'react';
import { Link } from 'react-router-dom';
import type { IAuthService } from '../../services/IAuthService';
import type { ILocalStorageService } from '../../services/ILocalStorageService';
import type IUIService from '../../services/IUIService';
import AuthPanelDialogWrapper from '../auth-panel-dialog-wrapper/auth-panel-dialog-wrapper.component';
import './activate-account.styles.scss';
import { PropsWithRouter, withRouter } from '../decorators/withRouter';

enum ActivationProgress {
    Loaded,
    Processing,
    Failed,
    Success,
}

interface ActivateAccountProps extends PropsWithRouter {

}

interface ConfirmRegistrationState {
    message: string | null;
    progress: ActivationProgress;
    navigateButton?: boolean
}

class ActivateAccount extends React.Component<
    ActivateAccountProps,
    ConfirmRegistrationState
> {
    success = false;

    @resolve('IAuthService')
    private readonly authService!: IAuthService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('ILocalStorageService')
    private readonly localStorageService!: ILocalStorageService;

    constructor(props: ActivateAccountProps) {
        super(props);
        this.state = {
            message: 'Processing...',
            progress: ActivationProgress.Processing,
        };
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        let key = searchParams.get('key');
        if (!key) {
            this.setState({
                message:
                    'Invalid authentication link! Please check your email for the correct link!',
                progress: ActivationProgress.Failed,
            });
        } else {
            this.uiService.showLoading();
            this.authService.activateUser(key).then((x) => {
                if (x.isSuccess && !!x.jwt) {
                    this.localStorageService.set('activate_jwt', x.jwt.jwt);
                    this.authService.setToken(x.jwt);
                }

                this.setState({
                    message: x.message,
                    progress: ActivationProgress.Success,
                }, ()=>{
                    const searchParams = new URLSearchParams(window.location.search);
                    const extra = searchParams.get('extra');
                    if(extra==='qrCode'){
                        const qrCode = this.localStorageService.get('qrGift');

                        if(!qrCode){
                           return; 
                        }

                        this.props.router.navigate('/qr-activation');
                    } else 
                    if(!!extra && extra.startsWith('qrC')){
                        const qrCode = extra.substring(3);
                        this.localStorageService.set('qrGift', qrCode);
                        if(!qrCode){
                           return; 
                        }

                        this.props.router.navigate('/qr-activation');
                    } else if (!!extra && extra==='store'){
                        this.setState({
                            ...this.state,
                            message: 'Please continue your purchase on Shopify!',
                        });
                    }   
                });
                this.uiService.hideLoading();
            });
        }
    }

    loginContent = () => {
        let loginLink = '/login';
        const searchParams = new URLSearchParams(window.location.search);
        const extra = searchParams.get('extra');
        if (!!loginLink && extra!=='store' && this.state.progress === ActivationProgress.Success) {
            loginLink += '?extra=' + extra;
            return (
                <>
                    Please <Link to={loginLink}>Login</Link> to continue!
                </>
            );
        }

        if(extra==='store'){
            return (
                <>
                    Click here to go to <a href='https://client.kingdomdeath.com'>client area</a> to manage your purchases.
                </>
            );
        }

        return (
            <>
                Go to <Link to={loginLink}>login!</Link>
            </>
        );
    };

    headerContent = () => {
        switch (this.state.progress) {
            case ActivationProgress.Success:
                return 'Account Authenticated!';
            case ActivationProgress.Failed:
                return 'Authentication Failed!';
            default:
                return 'Account Authentication!';
        }
    };

    message = () => {
        const { message } = this.state;
        return (
            message && (
                <React.Fragment>
                    <div className="message-container">
                        <p>{message}</p>
                        <p className="routing">{this.loginContent()}</p>
                    </div>
                </React.Fragment>
            )
        );
    };

    render() {
        return (
            <AuthPanelDialogWrapper popupClass={['success']}>
                <div className="login-dialog-row login-header">
                    <h3 className="header-text">{this.headerContent()}</h3>
                </div>
                {this.message()}
            </AuthPanelDialogWrapper>
        );
    }
}


export default withRouter(ActivateAccount)