//import Reaptcha, { ReaptchaProps } from "@panalbish/reaptcha-enterprise";
import React from 'react';
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';

interface KDMRecaptchaWrapperProps extends ReCAPTCHAProps {}

export default class KDMRecaptchaWrapper extends React.Component<
    KDMRecaptchaWrapperProps,
    {}
> {
    private recaptcha!: ReCAPTCHA | null;

    public async executeAsync(): Promise<string | null> {
        if (!this.recaptcha) return null;
        const token = await this.recaptcha.executeAsync();
        return token;
    }

    render() {
        return (
            <ReCAPTCHA
                size="invisible"
                ref={(e) => (this.recaptcha = e)}
                {...this.props}
            />
        );
    }
}
