import styles from './download-launcher.module.scss'

const DownloadLauncherButton = function () {
    const getPlatformDetails = () => {
        // @ts-ignore
        const currentFamily = window.platform.os.family as string;

        console.log(currentFamily);

        let isWindows = false;
        if (currentFamily.indexOf('Windows') >= 0) {
            isWindows = true;
        }

        return {
            active: isWindows
                ? process.env.PUBLIC_URL + '/winIcon.png'
                : process.env.PUBLIC_URL + '/macIcon.png',
            other: isWindows ? 'Mac' : 'PC',
            downloadLink: isWindows
                ? 'https://storage.googleapis.com/kdm-builds-bucket/Installer/PC/Launcher%20Of%20Death.exe'
                : 'https://storage.googleapis.com/kdm-builds-bucket/Installer/OSX/Launcher%20Of%20Death.dmg',
            otherDownloadLink: isWindows
                ? 'https://storage.googleapis.com/kdm-builds-bucket/Installer/OSX/Launcher%20Of%20Death.dmg'
                : 'https://storage.googleapis.com/kdm-builds-bucket/Installer/PC/Launcher%20Of%20Death.exe',
        };
    };

    const gameDetails = getPlatformDetails();


    return (
        <div className={styles.backgroundImageDiv}>
            <button className={styles.downloadLink} onClick={(e)=>{e.preventDefault(); window.open(gameDetails.downloadLink)}}>
                <div>
                    <p>Download The Launcher!</p>
                    <img
                        style={{
                            marginLeft: '10px',
                        }}
                        alt="platform"
                        src={gameDetails.active}
                    />
                </div>
            </button>
            <div className={styles.otherDownloadContainer}>
                <a
                    className={styles.otherDownload}
                    href={gameDetails.otherDownloadLink}
                >
                    Download for {gameDetails.other} instead?
                </a>
            </div>
        </div>
    );
};

export default DownloadLauncherButton;