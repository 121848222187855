import { useInjection } from 'inversify-react';
import { useSearchParams } from 'react-router-dom';
import { ILocalStorageService } from '../../services/ILocalStorageService';
import { useEffect, useState } from 'react';
import ITransService from '../../services/ITransService';
import AuthPanelDialogWrapper from '../auth-panel-dialog-wrapper/auth-panel-dialog-wrapper.component';
import data from '../../data/qrActivationText.json'
import styles from './qr-activation-page.module.scss'

export default function QRActivationPage() {
    const localStorageService = useInjection<ILocalStorageService>(
        'ILocalStorageService'
    );
    const transService = useInjection<ITransService>('ITransService');
    const [processing, setProcessing] = useState(true);
    const [message, setMessage] = useState(data.messages.processing);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const qrCode = localStorageService.get('qrGift');

        if (!!qrCode) {
            console.log('processing');
            transService.processProductKey(qrCode).then((x) => {
                if (!!x) {
                    setProcessing(false);
                    setSuccess(true);
                    setMessage(data.messages.success);
                }
                else{
                    setProcessing(false);
                    setSuccess(false);
                    setMessage(data.messages.fail);
                }
            });
        } else {
            setProcessing(false);
            setSuccess(false);
            setMessage(data.messages.fail);
        }

        localStorageService.set('qrGift', '');
    }, []);

    const loader = function(){
        if(!!processing){
            return (
                <div className={styles.loaderContainer}>
                    <div className={styles.loaderInnerContainer}>
                        <span className={styles.loader}></span>
                    </div>
                </div>
            )
        }
        return ('');
       
    }();

    const bottomSection = function(){
        if(!!success){
            return (
                <div className={styles.goToAccountContainer}><a href="https://client.kingdomdeath.com/account#account">Go To Your Account</a></div>
            )
        }
        return ('');
    }();

    return (
        <AuthPanelDialogWrapper
            popupClass={['success']}
        >
            <div className={styles.dialogContainer}>
                {message}
                {bottomSection}
            </div>
            {loader}
        </AuthPanelDialogWrapper>
    );
}
