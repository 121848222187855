import IUIService from '../IUIService';
import store from '../../redux/store';
import {
    setLoading,
    setLoadingWithText,
    setLoadingWithProgress,
    setProgress,
    setProgressMessage,
} from '../../redux/slices/loadingSlice';
import { injectable } from 'inversify';
import { hideConfirm, showConfirm } from '../../redux/slices/confirmDialogSlice';

@injectable()
export default class UIService implements IUIService {
    showProgressLoading = (progress: number, message: string | null) => {
        if(Number.isNaN(progress))
            progress = 0;
        store.dispatch(setLoadingWithProgress(true));
        store.dispatch(setProgress(progress));
        if (message === null) message = 'Loading!';
        setProgressMessage(message);
    };
    showLoading = () => {
        store.dispatch(setLoading(true));
    };
    showLoadingWithText = (text: string) => {
        store.dispatch(setLoadingWithText({
            show: true,
            text : text
        }));
    };
    hideLoading = () => {
        store.dispatch(setLoading(false));
    };

    showConfirmationDialog = (prompt: string, negativeButton? : string, positiveButton? :string)=>{
        store.dispatch(showConfirm({
            prompt : prompt,
            positiveButton : positiveButton,
            negativeButton : negativeButton
        }));

        return new Promise<boolean>((resolve, reject)=>{
            const unsubscribe = store.subscribe(()=>{
                resolve(store.getState().confirmDialog.response!);
                unsubscribe();
                store.dispatch(hideConfirm());
            })
        });
    }

    showSuccessNotification = (content: string) => {
        // @ts-ignore
        window.createNotification({
            closeOnClick: true,
            displayCloseButton: false,
            positionClass: 'nfc-bottom-right',
            showDuration: 3500,
            theme: 'success',
        })({ title: 'Success!', message: content });
    };
    showErrorNotification = (content: string) => {
        // @ts-ignore
        window.createNotification({
            closeOnClick: true,
            displayCloseButton: false,
            positionClass: 'nfc-bottom-right',
            showDuration: 3500,
            theme: 'error',
        })({ title: 'Error!', message: content });
    };
    showWarningNotification = (content: string) => {
        // @ts-ignore
        window.createNotification({
            closeOnClick: true,
            displayCloseButton: false,
            positionClass: 'nfc-bottom-right',
            showDuration: 3500,
            theme: 'warning',
        })({ title: 'Warning!', message: content });
    };
}
