import LoginPanel from "../../components/login-panel/login-panel.component"

function LoginPage(){    
    document.title = 'Kingdom Death: Simulator'
    return (
        <LoginPanel/>
    )
}

export default LoginPage
