import ICookieService from '../ICookieService';
import Cookies from 'js-cookie';
import { injectable } from 'inversify';

@injectable()
export default class CookieService implements ICookieService {
    getExpirationAttribute(cookieAttributes: Cookies.CookieAttributes): Cookies.CookieAttributes {
        const expiration = new Date();
        expiration.setFullYear(expiration.getFullYear()+1);
        return {
            ...cookieAttributes,
            expires: expiration
        };
    }
    set(
        name: string,
        value: string,
        options?: Cookies.CookieAttributes | undefined
    ): void {
        Cookies.set(name, value, options);
    }

    get(name: string) {
        return Cookies.get(name);
    }

    getDomainAttribute(cookieAttributes : Cookies.CookieAttributes,domain:string) : Cookies.CookieAttributes{
        return {
            ...cookieAttributes,
            domain : domain
        };
    }

    remove(name : string){
        Cookies.remove(name);
    }
}
