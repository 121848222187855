import { useInjection } from 'inversify-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IAuthService } from '../../services/IAuthService';
import IUIService from '../../services/IUIService';
import { IUserValidator } from '../../services/IUserValidator';
import { IWindowMessage } from '../../services/IWindowMessage';
import conditionalClass from '../../utils/conditional-class';
import AuthPanelDialogWrapper from '../auth-panel-dialog-wrapper/auth-panel-dialog-wrapper.component';
import textData from '../../data/logintext.json';
import { LoginState } from '../../models/LoginState';
import ConfirmAccountComponent from '../confirm-account-component/confirm-account.component';
import IExtraService from '../../services/IExtraService';
import DownloadLauncherButton from '../download-launcher-button/download-launcher-button.component';
import { ILocalStorageService } from '../../services/ILocalStorageService';
import LoginPageFromStore from './login-panel-from-store/LoginPageFromStore';

export default function LoginPanel() {
    const authService = useInjection<IAuthService>('IAuthService');
    const userValidator = useInjection<IUserValidator>('IUserValidator');
    const windowMessage = useInjection<IWindowMessage>('IWindowMessage');
    const uiService = useInjection<IUIService>('IUIService');
    const extraService = useInjection<IExtraService>('IExtraService');
    const localStorageService = useInjection<ILocalStorageService>(
        'ILocalStorageService'
    );
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loginFinished, setLoginFinished] = useState(false);
    const [needsConfirmation, setNeedsConfirm] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);

    const from = searchParams.get('from');
    const isFromStore = from && from === 'store';

    useEffect(() => {
        if (!!isFromStore) {
            return;
        }
        const canInitfromCookies = authService.initFromCookies();
        if (!!canInitfromCookies) {
            loginClick(true);
        }
    }, []);

    if (isFromStore) {
        return <LoginPageFromStore />;
    }

    const isActivate = from && from === 'gift';

    const extra = searchParams.get('extra');
    const hasExtra = !!extra;

    const invite = searchParams.get('invite');
    const hasInvite = !!invite;

    const to = searchParams.get('to');

    const goBackToReferrer = !!searchParams.get('referred');

    const loginClick = async (usingLocal?: boolean) => {
        uiService.showLoading();
        let response: LoginState = LoginState.Fail;
        if (!usingLocal) {
            response = await authService.login(email, password);
        } else {
            const localToken = await authService.getToken();
            if (!!localToken) {
                response = LoginState.Success;
            } else {
                uiService.hideLoading();
                return;
            }
        }

        uiService.hideLoading();
        if (response === LoginState.Success) {
            windowMessage.postMessageToSpawner({
                key: 'jwt',
                value: await authService.getToken(),
            });
            windowMessage.postMessageToSpawner({
                key: 'detailed',
                value: authService.getTokenLocal(),
            });
            setLoginFinished(true);
            if (hasExtra) {
                uiService.showLoading();
                const extraData = await extraService.parseExtra(extra);
                uiService.hideLoading();
                if (!!extraData) {
                    uiService.showLoading();
                    let navigateUrl = extraData.url;
                    if (extraData.requiresJWT) {
                        const token = authService.getTokenLocal();
                        if (navigateUrl.indexOf('?') !== -1) navigateUrl += '&';
                        else navigateUrl += '?';
                        navigateUrl += 'token=' + token.jwt;
                        navigateUrl += '&expires=' + token.expires;
                        navigateUrl += '&refresh=' + token.refreshToken;
                    }
                    window.location.replace(navigateUrl);
                    return;
                }
            }
            if (!!to && to === 'core') {
                uiService.showLoading();
                uiService.showLoading();
                let navigateUrl =
                    'https://store.kingdomdeath.com/gift-code?product=core';
                const token = authService.getTokenLocal();
                if (navigateUrl.indexOf('?') !== -1) navigateUrl += '&';
                else navigateUrl += '?';
                navigateUrl += 'token=' + token.jwt;
                navigateUrl += '&expires=' + token.expires;
                navigateUrl += '&refresh=' + token.refreshToken;
                window.location.replace(navigateUrl);
                return;
            }
            if (!!to && to === 'account') {
                let navigateUrl = 'https://client.kingdomdeath.com';
                window.location.replace(navigateUrl);
                return;
            }
            if (!!to && to === 'core') {
                uiService.showLoading();
                uiService.showLoading();
                let navigateUrl =
                    'https://store.kingdomdeath.com/gift-code?product=core';
                const token = authService.getTokenLocal();
                if (navigateUrl.indexOf('?') !== -1) navigateUrl += '&';
                else navigateUrl += '?';
                navigateUrl += 'token=' + token.jwt;
                navigateUrl += '&expires=' + token.expires;
                navigateUrl += '&refresh=' + token.refreshToken;
                window.location.replace(navigateUrl);
                return;
            }
            if (goBackToReferrer && !!document.referrer) {
                window.location.replace(document.referrer);
                return;
            }
            if (!!hasInvite) {
                localStorageService.set('qrGift', invite);
                navigate('/qr-activation');
                return;
            }
            window.location.replace('https://client.kingdomdeath.com');
        } else {
            if (response === LoginState.NeedsActivation) {
                if (isFromStore || isActivate) {
                    const timer = setInterval(async () => {
                        const login = await authService.loginJWT(
                            email,
                            password
                        );
                        if (!login) return;
                        if (isFromStore || isActivate) {
                            windowMessage.postMessageToSpawner({
                                key: 'jwt',
                                value: login.jwt,
                            });
                        }
                        clearInterval(timer);
                    }, 1000);
                }

                setNeedsConfirm(true);
            } else {
                setError('Invalid email or password!');
            }
        }
    };

    const hasError = error !== '';

    const buttonEnabled =
        userValidator.validateEmail(email).isSuccess && password.length > 0;

    const loginHeader = () => {
        if (isFromStore) return textData.storeHeader;
        if (isActivate) return textData.activateHeader;
        return textData.defaultHeader;
    };

    const loginInfoHeader = () => {
        if (isFromStore) return textData.storeInfoHeader;
        if (isActivate) return textData.activateInfoHeader;
        return textData.defaultInfoHeader;
    };

    const loginSuccessMessage = () => {
        if (isFromStore) return textData.storeLoginSuccess;
        if (isActivate) return textData.activateLoginFinishSuccess;
        return textData.defaultLoginSuccess;
    };

    const loginSuccessHeader = () => {
        if (isActivate) return textData.activateSuccessHeader;
        if (isFromStore) return textData.storeLoginFinishHeader;
        return textData.defaultLoginFinishHeader;
    };

    const confirmText = () => {
        if (isFromStore) return textData.storeConfirmText;
        if (isActivate) return textData.activateConfirmText;
        return textData.defaultConfirmText;
    };

    const resend = () => {
        authService.resendEmail(email, null);
    };

    const registrationLink = () => {
        return `/register${
            isFromStore ? '?from=store' : isActivate ? `?from=gift` : ''
        }`;
    };

    const renderDefaultBody = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row login-header">
                    <span>{loginHeader()}</span>
                </div>
                <div className="login-dialog-row info-message">
                    <span>{loginInfoHeader()}</span>
                </div>
                <div className="login-dialog-row input-row">
                    <input
                        type="email"
                        id="wod-email"
                        className={hasError ? 'has-error' : undefined}
                        value={email}
                        onChange={(e) => {
                            e.preventDefault();
                            setEmail(e.target.value);
                        }}
                        placeholder="Email"
                    />
                </div>

                <div className="login-dialog-row input-row">
                    <input
                        type="password"
                        id="wod-password"
                        className={hasError ? 'has-error' : undefined}
                        value={password}
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                        }}
                        placeholder="Password"
                    />
                </div>
                <div
                    className="login-dialog-row input-row error-container"
                    style={{
                        display: !hasError ? 'none' : 'flex',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="red"
                        viewBox="0 0 16 16"
                    >
                        <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                    <p>{error}</p>
                </div>

                <div className="button-container login-dialog-row input-row">
                    <input
                        type="button"
                        onClick={() => loginClick()}
                        id="wod-login-btn"
                        value="Log In"
                        disabled={!buttonEnabled}
                    />
                </div>

                <div className="login-dialog-row forgot-password-section">
                    <Link
                        to="/forgot-password"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Forgot Password?
                    </Link>
                    <p>
                        Don't have an account yet?{' '}
                        <Link to={registrationLink()}>Create now!</Link>
                    </p>
                </div>

                <div className="bottom-container login-dialog-row">
                    <a
                        rel="noreferrer"
                        href="https://kingdomdeath.com/legal/terms"
                        target="_blank"
                    >
                        Terms
                    </a>
                    <a
                        rel="noreferrer"
                        href="https://kingdomdeath.com/legal/privacy"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </div>
            </React.Fragment>
        );
    };

    const loginSuccessExtraInfo = (function () {
        const isExtraOrStore = !!hasExtra || !!to;
        let navigateUrl = 'https://store.kingdomdeath.com/gift-code';
        const token = authService.getTokenLocal();
        navigateUrl += '?token=' + token.jwt;
        navigateUrl += '&expires=' + token.expires;
        navigateUrl += '&refresh=' + token.refreshToken;
        switch (isExtraOrStore) {
            case true:
                return (
                    <div>
                        Please do not close this page. You will be redirected
                        shortly. If you are not{' '}
                        <a href={navigateUrl}>click here.</a>
                    </div>
                );
            default:
                return (
                    <div>
                        <p>
                            To redeem your product gift codes{' '}
                            <a href={navigateUrl}>visit here.</a>
                        </p>
                        <p>
                            To go to our shop{' '}
                            <a href="https://shop.kingdomdeath.com">
                                visit here.
                            </a>
                        </p>
                        <div className="launcher-download">
                            <p>Our launcher can be downloaded below.</p>
                            <DownloadLauncherButton />
                        </div>
                    </div>
                );
        }
    })();

    const renderLoginFinishBody = () => {
        return (
            <React.Fragment>
                <div className="login-dialog-row success-row">
                    <h3>{loginSuccessHeader()}</h3>
                </div>
                <div className="login-dialog-row success-row">
                    <p>{loginSuccessMessage()}</p>
                </div>
                {loginSuccessExtraInfo}
            </React.Fragment>
        );
    };

    const renderConfirmBody = () => {
        return (
            <ConfirmAccountComponent
                message={confirmText()}
                timeout
                resend={resend}
            />
        );
    };

    const body = !loginFinished
        ? !needsConfirmation
            ? renderDefaultBody()
            : renderConfirmBody()
        : renderLoginFinishBody();

    const popupClass = [
        conditionalClass(
            loginFinished || needsConfirmation,
            'success',
            undefined
        ),
    ];

    return (
        <AuthPanelDialogWrapper popupClass={popupClass}>
            {body}
        </AuthPanelDialogWrapper>
    );
}
